<template>
  <div id="app">
    <header id="header" class="fixed-top">
    <div class="container ">

      <!-- <h1 class="logo mr-auto"><router-link to="/">WeekFlash</router-link></h1>-->
      <!-- Uncomment below if you prefer to use an image logo -->
      <a href="/" class="logo mr-auto"><img src="/assets/img/logoGlix.png" alt="Logo Glix" class="center-block"></a>
      
     
      

    </div>
  </header><!-- End Header -->
    <router-view/>

    <footer id="footer">
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright {{ year }} <strong><span>Glix</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/enno-free-simple-bootstrap-template/ -->
        <router-link to="/termsofuse">Terms of use</router-link> • <router-link to="/privacypolicy">Privacy Policy</router-link> • <router-link to="/faq">F.A.Q.</router-link> • <router-link to="/securitycenter">Security Center</router-link>
      </div>
    </div>
  </footer><!-- End Footer -->
  </div>
</template>

<script>


export default {
    data: function() {
       return {
          year: new Date().getFullYear()
        }
    }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
