import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:country/@:userName',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserView.vue')
  },
  {
    path: '/comofunciona',
    name: 'Como funciona',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowItWorksView.vue')
  },
  {
    path: '/politicadeprivacidad',
    name: 'Politica de Privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyEnView.vue')
  },
  {
    path: '/terminosdeuso',
    name: 'Terminos de Uso',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/termsofuse',
    name: 'Terms of Use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUseEnView.vue')
  },
  {
    path: '/centrodeseguridad',
    name: 'Centro de Seguridad',
    component: () => import(/* webpackChunkName: "about" */ '../views/SafetyCenterView.vue')
  },
  {
    path: '/securitycenter',
    name: 'Security Center',
    component: () => import(/* webpackChunkName: "about" */ '../views/SafetyCenterEnView.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqEnView.vue')
  },
  {
    path: '/preguntasfrecuentes',
    name: 'Preguntas Frecuentes',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
